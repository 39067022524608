import React from "react";
import PrivacyPolicyContent from "../views/PrivacyPolicyContent";
import { MetaDataHead } from "../components";
import { useRedirectByLang } from "../hooks";

const PrivacyPolicyPage = () => {
  useRedirectByLang();
  return (
    <>
      <MetaDataHead>
        <link rel="preload" href={`${process.env.GATSBY_ROOT_PATH}/bg-01.jpg`} as="image" />
      </MetaDataHead>
      <PrivacyPolicyContent />
    </>
  );
};

export default PrivacyPolicyPage;
